$(document).on("turbolinks:load", function () {
  if ($('.js-add-cash-credit-btn').is('*')) {
    $('.js-add-cash-credit-btn').on('click', function(event){
      event.preventDefault();
      event.stopPropagation();

      $('.js-add-cash-credit-btn').addClass('d-none');
      $('.js-cash-credit-field').removeClass('d-none');
    });

    $('.js-remove-cash-credit-btn').on('click', function(event){
      event.preventDefault();
      event.stopPropagation();

      $('.js-cash-credit-field').addClass('d-none');
      $('.js-cash-credit-field input').val(0.0);
      $('.js-add-cash-credit-btn').removeClass('d-none');
    });
  }

});