import { Controller } from "stimulus";

export default class extends Controller {

  connect(){
    var controller = this;
    controller.setupPaymentTypeButtons();

  	if($('.js-new-credit-card-fields').is('*')){
  		var cardElement = this.setupStripeElements();

      $('#js-reconcile-invoices-form').on('submit', function(event){
        // Disable before any other JS is run
        $('#js-process-payment-btn').prop('disabled', true);
        $('#js-process-payment-btn').html('<i class="fas fa-lock mr-2"></i>Processing Payment ...');

        if($('#process-via-stripe-switch').is(":checked") && $('#js-credit-card-type input[type="radio"]').is(':checked') && $('#js-new-card-btn input[type="radio"]').is(':checked') && $('#js-stripe-payment-id').val() == ''){
          $('#js-card-error').hide();
          controller.confirmCardPayment(cardElement);

          event.preventDefault();
        }
      });
  	}

    if($('.js-select-payment-term').is('*')){
      $('.js-select-payment-term').selectpicker();
      
      $('.js-select-payment-term').on('change', function(){
        var selectedOption = $('.js-select-payment-term option:selected');

        $('#js-amount-input-field').val(selectedOption.data('amount'));

        if(selectedOption.val() == ''){
          $('.js-payment-term-other-amount').show();
          $('.js-payment-term-payment-disabled').show();
        } else {
          $('.js-payment-term-other-amount').hide();
          $('.js-payment-term-payment-disabled').hide();
        }

      });
    }

  }

  updateAmountMaxInput(){
    var amountRemaining = parseFloat($('#js-amount-input-field').data('amountRemaining'));
    var processingFee = parseFloat($('#js-amount-input-field').data('processingFee'));

    if($('#process-via-stripe-switch').is(":checked")){
      var amountInputMax = amountRemaining + processingFee;
      $('#js-includes-processing-fee').show();
    } else {
      var amountInputMax = amountRemaining;
      $('#js-includes-processing-fee').hide();
    }
  }

  setupStripeElements(){
    var stripElements = window.stripe.elements()
    var style = {
      base: {
        color: '#32325d',
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#32325d'
        }
      },
      invalid: {
        fontFamily: 'Arial, sans-serif',
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    }

    var cardElement = stripElements.create('card', { style: style });
    cardElement.mount('#card-element');

    cardElement.on('change', function(event){
      // Disable the Pay button if there are no card details in the Element
      $('#js-process-payment-btn').prop('disabled', event.empty)

      if(event.empty) {
        $('#js-process-payment-btn').html('<i class="fas fa-lock mr-2"></i>Process Payment');
      } else {
        $('#js-process-payment-btn').html('<i class="fas fa-lock mr-2"></i>Process Payment');
      }

      if (event.error) {
        $('#js-card-error').show();
        document.querySelector('#js-card-error').textContent = event.error.message
      } else {
        $('#js-card-error').hide();
        document.querySelector('#js-card-error').textContent = ''
      }
    });

    return cardElement;
  }

  setupPaymentTypeButtons() {
    $('#process-via-stripe-switch').on('click', function(){
      if($(this).is(':checked')){
        $('#js-client-payment-methods .js-payment-method-option').prop('disabled', false);
        $('#js-process-payment-btn').html('<i class="fas fa-lock mr-2"></i>Process Payment');
      } else {
        $('#js-client-payment-methods .js-payment-method-option').prop('disabled', true);
        $('#js-process-payment-btn').html('<i class="fas fa-lock mr-2"></i>Process Payment');
      }
    });

    $('#js-payment-type-buttons label').on('click', function() {
      var amountField = $('#js-amount-input-field');
      var invoiceMaxValue = amountField.data('max');

      if($(this).attr('id') != 'js-credit-card-type'){
        $('#js-client-payment-methods').slideUp('fast', function(){
          $('#js-client-payment-methods input').prop('disabled', true);
        });
      } else {
        $('#js-client-payment-methods input').prop('disabled', false);
        $('#js-client-payment-methods').slideDown('fast');
      }

      if( $(this).attr('id') == 'js-cash-credit-type' ) {
        var creditMaxValue = $(this).data('max');
        $('#js-cash-credit-balance').slideDown('fast');

        if (parseFloat(creditMaxValue) < parseFloat(invoiceMaxValue)) {
          amountField.prop('max', creditMaxValue);
          amountField.val(creditMaxValue);
        }

      } else {
        $('#js-cash-credit-balance').slideUp('fast');
        amountField.prop('max', invoiceMaxValue);
      }
    });
  }

  confirmCardPayment(cardElement) {
    var name = $('#js-client-full-name').val();
    var email = $('#js-client-email').val();
    var phone = $('#js-client-phone').val();
    var clientToken = $('#card-element').data('client');
    var paymentIntentUrl = $('#card-element').data('getPaymentIntentUrl');
    var amountToCharge = $('#js-amount-input-field').val();

    $.ajax({
      url: paymentIntentUrl,
      type: "POST",
      beforeSend: function(xhr){
        xhr.setRequestHeader('X-CSRF-Token', localStorage.getItem("bp-x-csrf-token"));
      },
      data: { 'amount_to_charge': amountToCharge },
      dataType: 'json',
      success: function (data) {
        if(data.success) {
          stripe.confirmCardPayment(data.client_secret, {
            setup_future_usage: "off_session", // Keep card on file
            payment_method: {
              card: cardElement,
              billing_details: {
                name: name,
                email: email,
                phone: phone
              }
            }
          }).then((result) => {
            if (result.error) {
              $('#js-card-error').show();
              $('#js-card-error').html(result.error.message);
              $('#js-process-payment-btn').prop('disabled', false);
              $('#js-process-payment-btn').html('<i class="fas fa-lock mr-2"></i>Process Payment');
            } else {
              var stripePaymentId = result.paymentIntent.id;
              var stripePaymentMethod = result.paymentIntent.payment_method;
              var stripeCharged = result.paymentIntent.amount;

              $('#js-stripe-payment-id').val(stripePaymentId);
              $('#js-stripe-payment-method').val(stripePaymentMethod);
              $('#js-stripe-payment-charged-amount').val(stripeCharged);

              // Resubmit
              $('#js-reconcile-invoices-form').submit();
            }
          });

        } else {
          $('#js-card-error').show();
          $('#js-card-error').html(data.message);
          $('#js-process-payment-btn').prop('disabled', false);
          $('#js-process-payment-btn').html('<i class="fas fa-lock mr-2"></i>Process Payment');
        }
      },
      error: function (){
        $('#js-card-error').show();
        $('#js-card-error').html("Something went wrong. Please try again.");
        $('#js-process-payment-btn').prop('disabled', false);
        $('#js-process-payment-btn').html('<i class="fas fa-lock mr-2"></i>Process Payment');
      }
    });
  }
}