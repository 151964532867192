$(document).on("turbolinks:load", function () {
  $(".js-add-tag-form-btn").click(function () {
    $(this).animate({width:'toggle'}, 150, function(){
      $(this).siblings(".js-form-tags").animate({width:'toggle'}, 150);
    });
  });

  $('.js-form-tags.js-can-add-tags .btn').click(function(){
     var tag_id = $(this).data('tag-id');
     var client_id = $(this).data('client-id');
     var pet_id = $(this).data('pet-id');
     var service_id = $(this).parents('.service-tags').data('service-id');
     var series_id = $(this).parents('.service-tags').data('series-id');
     var exclusive = $(this).parents('.service-tags').data('exclusive');
     var inclusive = $(this).parents('.service-tags').data('inclusive');

     submitTag($(this), tag_id, client_id, pet_id, service_id, series_id, exclusive, inclusive)
  });

  bindHoverAndDestroy();

});

function bindHoverAndDestroy(){
  $('.js-tag').hover(
    function(){
      $(this).find('.js-destroy-tag').removeClass('d-none');
    }, function(){
      $(this).find('.js-destroy-tag').addClass('d-none');
    }
  );

  $('.js-destroy-tag').click(function(){
    var element = $(this).parents('.js-tag')
    var tag_id = element.data('tag-id');
    var client_id = element.parents('#custom-tags').data('client-id');
    var pet_id = element.parents('#custom-tags').data('pet-id');
    var service_id = element.parents('.service-tags').data('service-id');
    var series_id = element.parents('.service-tags').data('series-id');
    var exclusive = element.parents('.service-tags').data('exclusive');
    var inclusive = element.parents('.service-tags').data('inclusive');

    destroyTag(element, tag_id, client_id, pet_id, service_id, series_id, exclusive, inclusive)
 });
}

function submitTag(element, tag_id, client_id, pet_id, service_id, series_id, exclusive, inclusive) {

  $.ajax({
    url: '/business/tags',
    type: "POST",
    beforeSend: function(xhr){
      xhr.setRequestHeader('X-CSRF-Token', localStorage.getItem("bp-x-csrf-token"));
    },
    data: {
      tag_id: tag_id,
      client_id: client_id,
      pet_id: pet_id,
      service_id: service_id,
      series_id: series_id,
      exclusive: exclusive,
      inclusive: inclusive
    },
    dataType: 'script',
    success: () => {
      element.parents('.js-form-tags').animate({width:'toggle'}, 150, function(){
        element.parents('.js-form-tags').siblings(".js-add-tag-form-btn").animate({width:'toggle'}, 150);
        element.remove();
        bindHoverAndDestroy();
      });
    },
    error: () => {
      window.notyf.error(
        'There was an error adding this tag, please try again.'
      );
    }
  });
}

function destroyTag(element, tag_id, client_id, pet_id, service_id, series_id, exclusive, inclusive) {

  $.ajax({
    url: '/business/tags/'+tag_id,
    type: "DELETE",
    beforeSend: function(xhr){
      xhr.setRequestHeader('X-CSRF-Token', localStorage.getItem("bp-x-csrf-token"));
    },
    data: {
      client_id: client_id,
      pet_id: pet_id,
      service_id: service_id,
      series_id: series_id,
      exclusive: exclusive,
      inclusive: inclusive
    },
    dataType: 'json',
    success: () => {
      element.remove();
    },
    error: () => {
      window.notyf.error(
        'There was an error removing this tag, please try again.'
      );
    }
  });
}
