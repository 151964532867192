import { Controller } from "stimulus";

export default class extends Controller {

  validateForm(event) {
    var termAmountSum = $('.js-term-amount:visible').map(function() {
        return parseInt($(this).val(), 10) || 0; // Parse as an integer, default to 0 if invalid
    }).get().reduce(function(total, value) {
        return total + value;
    }, 0);    

    var values = $('.js-term:visible').map(function() {
        return $(this).val();
    }).get();

    var hasNoDuplicateDays = new Set(values).size === values.length;

    var allValues = $('.js-term:visible, .js-term-amount:visible').map(function() {
      return $(this).val();
    }).get();

    var hasNoBlanks = !allValues.some(val => val === '');

    var allTermsInOrder = this.allTermsInOrder();

    if(termAmountSum == 100 && hasNoDuplicateDays && hasNoBlanks && allTermsInOrder) {
      $('.js-payment-terms-warning').slideUp();
      $('.js-payment-terms-submit').prop('disabled', false);
    } else {
      $('.js-payment-terms-warning').slideDown();
      $('.js-payment-terms-submit').prop('disabled', true);
    }
  }

  allTermsInOrder() {
    var terms = $('.js-term:visible').map(function() {
      return $(this).val();
    }).get();

    const nums = _.map(terms, term => parseInt(term, 10));
    return !_.some(nums, isNaN) && _.isEqual(nums, _.sortBy(nums));
  }
  
  validateFormDelayed(event) {
    var controller = this;

    setTimeout((function() {
      controller.validateForm(event);
    }), 250);
  }

  openDefaultPaymentTermsModal(event){
    event.preventDefault();

    $('#payment-terms-modal').modal();
    $('#payment-terms-modal .modal-dialog-loading').show();
    $('#payment-terms-modal .modal-dialog-loaded').hide();

    var modalUrl = $(event.target).data('modalUrl');

    fetch(modalUrl)
      .then(response => response.text())
      .then(function(html){
        $('#payment-terms-modal .modal-dialog-loading').hide();
        $('#payment-terms-modal .modal-dialog-loaded').show();
        $('#payment-terms-modal .modal-dialog-loaded').html(html);
      });
  }

  getPaymentTerms() {
    var paymentTerms = [];

    $(".js-payment-term-option:visible").each(function () {
      var termAmount = $(this).find(".js-term-amount").val() || "";
      var term = $(this).find(".js-term").val() || "";
      var autocharge = $(this).find(".js-toggler-toggle-show-hide-target").is(":checked");

      paymentTerms.push({
        term_amount: termAmount,
        term: term,
        autocharge: autocharge
      });
    });

    return paymentTerms;
  }

  handleSubmit(event) {
    var paymentTerms = this.getPaymentTerms();
    var paymentTermsHtml = '';

    $(paymentTerms).each(function(_, paymentTerm) {
      var autocharge = paymentTerm.autocharge;
      var term = paymentTerm.term;
      var termAmount = paymentTerm.term_amount;

      if(termAmount == '100' && term == "0") {
        var summary = "Due on receipt";
      } else {
        var summary = termAmount + "% ";

        if(term == "0"){
          summary += "due on receipt";
        } else {
          summary += "due after " + term + (term == '1' ? " day" : " days"); 
        }

      }

      if(autocharge) {
        summary += '<spandata-html="true" data-original-title="BusyPaws will automatically charge this invoice when due if the client has a credit card on file." data-placement="top" data-toggle="tooltip"><i class="fas fa-bolt ml-1 text-info"></i></span>';
      }

      paymentTermsHtml += "<div class='mb-2'>" + summary + "</div>";
    });

    var paymentFormInputHtml = $('#payment-terms-form-input-html input').map(function() {
        let $input = $(this);
        return `<input type="${$input.attr('type')}" name="${$input.attr('name')}" value="${$input.val()}" ${$input.prop('checked') ? 'checked' : ''} ${$input.is(':disabled') ? 'disabled' : ''}>`;
    }).get().join('');

    $('#js-invoice-payment-terms-summary').html(paymentTermsHtml);
    $('#invoice-payment-terms-form-input-html').html(paymentFormInputHtml);

    $('[data-toggle="tooltip"]').tooltip();
    $('#payment-terms-modal').modal('hide');
    event.preventDefault();
  }

}