$(document).on("turbolinks:load", function () {
  if ($(".js-payment-methods-list").is("*")) {
    bindActions();
  }
});

function bindActions() {
  $(".js-payment-methods-list button").on('click', function(){
    $(this).parents('.js-payment-methods-list').find('button').removeClass('btn-primary').addClass('btn-outline-primary');
    $(this).removeClass('btn-outline-primary').addClass('btn-primary');
  });

  $(".js-pay-invoice").on('click', function(){
    var submitButton = $(this);
    var pmid = submitButton.parents('.modal-body').find('ul li .btn-primary').parent().data('pmid');
    var invoiceId = submitButton.data('invoiceid');

    submitButton.prop('disabled', true);

    if(pmid){
      submitPayment(invoiceId, pmid, submitButton);

    } else {
      window.notyf.error(
        'No payment method selected.'
      );
      submitButton.prop('disabled', false);
    }
  });
}

function submitPayment(invoiceId, pmid, button) {
  $.ajax({
    url: button.data('url'),
    type: "POST",
    beforeSend: function(xhr) {
      xhr.setRequestHeader('X-CSRF-Token', localStorage.getItem("bp-x-csrf-token"));
    },
    data: {
      invoice: invoiceId,
      payment_method: pmid
    },
    dataType: 'json',
    success: function() {
      window.location.reload();
    },
    error: function(response) {
      button.prop('disabled', false);

      if (response.message) {
        var message = response.message;
      } else {
        var message = 'Sorry, there was an issue processing this payment. Please try again or contact support.'
      }
      window.notyf.error(
        message
      );
    }
  });
}