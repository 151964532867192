import { Controller } from "stimulus";

export default class extends Controller {

  validateForm(event) {
    var termAmountSum = $('.js-term-amount:visible').map(function() {
        return parseInt($(this).val(), 10) || 0; // Parse as an integer, default to 0 if invalid
    }).get().reduce(function(total, value) {
        return total + value;
    }, 0);    

    var values = $('.js-term:visible').map(function() {
        return $(this).val();
    }).get();

    var hasNoDuplicateDays = new Set(values).size === values.length;

    var allValues = $('.js-term:visible, .js-term-amount:visible').map(function() {
      return $(this).val();
    }).get();

    var hasNoBlanks = !allValues.some(val => val === '');

    var allValues = $('.js-term:visible, .js-term-amount:visible').map(function() {
      return $(this).val();
    }).get();

    var hasNoBlanks = !allValues.some(val => val === '');

    var allTermsInOrder = this.allTermsInOrder();

    if(termAmountSum == 100 && hasNoDuplicateDays && hasNoBlanks && allTermsInOrder) {
      $('.js-default-payment-terms-warning').slideUp();
      $('.js-default-payment-terms-submit').prop('disabled', false);
    } else {
      $('.js-default-payment-terms-warning').slideDown();
      $('.js-default-payment-terms-submit').prop('disabled', true);
    }
  }

  allTermsInOrder() {
    var terms = $('.js-term:visible').map(function() {
      return $(this).val();
    }).get();

    const nums = _.map(terms, term => parseInt(term, 10));
    return !_.some(nums, isNaN) && _.isEqual(nums, _.sortBy(nums));
  }

  validateFormDelayed(event) {
    var controller = this;

    setTimeout((function() {
      controller.validateForm(event);
    }), 250);
  }  

  openDefaultPaymentTermsModal(event){
    event.preventDefault();

    $('#default-payment-terms-modal').modal();
    $('#default-payment-terms-modal .modal-dialog-loading').show();
    $('#default-payment-terms-modal .modal-dialog-loaded').hide();

    var modalUrl = $(event.target).data('modalUrl');

    fetch(modalUrl)
      .then(response => response.text())
      .then(function(html){
        $('#default-payment-terms-modal .modal-dialog-loading').hide();
        $('#default-payment-terms-modal .modal-dialog-loaded').show();
        $('#default-payment-terms-modal .modal-dialog-loaded').html(html);

        $('[data-toggle="tooltip"]').tooltip();
      });
  }

}