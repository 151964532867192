$(document).on("turbolinks:load", function () {

  if ($('.js-add-payment-method-btn').is('*')) {
    $('.js-add-payment-method-btn').click( function(){
      $('.js-add-payment-method-btn').hide();
      $('.js-add-payment-method').show();
    });
  }

  if ($('.js-edit-billing-address-btn').is('*')) {
    $('.js-edit-billing-address-btn').click( function(){
      $('.js-edit-billing-address-btn').hide();
      $('.js-billing-address-card').hide();
      $('.js-billing-address-form').show();
    });
  }

  if ($('#js-subscribe-to-plan').is('*')) {
    var submitButton = $('#js-subscribe-to-plan');
    var recalcUrl = submitButton.data('recalc-url');

    // This is for the New Subscription path only because we need to save/create the Payment Method
    if($('#card-element').is('*')){
      var cardElement = createStripeElements(submitButton);

      document.getElementById('stripe-payment-form-submit').addEventListener('submit', function(event){
        event.preventDefault();
        // Add the payment method to Stripe when payment is attempted, then submits to the back-end to create the subscription
        createPaymentMethod(stripe, cardElement)
      });
    }

    $('.js-number-of-seats-input, .js-number-of-lite-seats-input, .js-number-of-facilities-input, #discount-code-to-apply').on('change', () => {
      getSubscriptionTotals(recalcUrl);
    });

    getSubscriptionTotals(recalcUrl);
  }
});


function createStripeElements(submitButton) {
  // Create the Stripe card input field
  var stripElements = window.stripe.elements()
  var style = {
    base: {
      color: '#32325d',
      fontFamily: 'Arial, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#32325d'
      }
    },
    invalid: {
      fontFamily: 'Arial, sans-serif',
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }

  var cardElement = stripElements.create('card', { style: style });
  cardElement.mount('#card-element');

  cardElement.on('change', function(event){
    // Disable the Pay button if there are no card details in the Element

    submitButton.prop('disabled', event.empty);

    if (event.error) {
      document.querySelector('#js-card-error').textContent = event.error.message
    } else {
      document.querySelector('#js-card-error').textContent = ''
    }
  });

  return cardElement
}

function createPaymentMethod(stripe, cardElement) {
  loading(true);

  stripe.createPaymentMethod({
    type: 'card',
    card: cardElement
  }).then(function(result){
    if(result.error){
      showError(result.error.message);
    } else {
      paymentMethodInput = document.getElementById('stripe_payment_method_id');
      paymentMethodInput.value = result['paymentMethod']['id'];
      document.getElementById('stripe-payment-form-submit').submit();
    }
  });
}

// Show the customer the error from Stripe if their card fails to charge
function showError(errorMsgText) {
  loading(false);

  var errorMsg = document.querySelector('#js-card-error')

  errorMsg.textContent = errorMsgText
  setTimeout( function(){
    errorMsg.textContent = ''
  }, 4000);
}

// Show a spinner on payment submission
function loading(isLoading){
  var submitButton = $('#js-subscribe-to-plan');
  var spinner = $('#js-spinner');
  var text = $('#js-button-text');
  var discountCodeInput = $('#js-button-text');

  if (isLoading) {
    // Disable the button and show a spinner
    submitButton.prop('disabled', true);
    spinner.removeClass('d-none');
    text.addClass('d-none');
    discountCodeInput.prop('disabled', true);
  } else {
    submitButton.prop('disabled', false);
    spinner.addClass('d-none');
    text.removeClass('d-none');
    discountCodeInput.prop('disabled', false);
  }
}

function getSubscriptionTotals(url) {
  loading(true);

  $.ajax({
    url: url,
    type: "POST",
    beforeSend: function(xhr){
      xhr.setRequestHeader('X-CSRF-Token', localStorage.getItem("bp-x-csrf-token"));
    },
    data: getParams(),
    dataType: 'json',
    success: (response) => {
      loading(false);
      updateTotals(response);
    },
    error: () => {
      window.notyf.error(
        "We're sorry, something has gone wrong! No charges have been made, you may try again or contact us for assistance."
      );
    }
  });
}

function getParams() {
  var newSub = $('#new_sub').val()
  var plan = $('#plan').val();
  var frequency = $('#frequency').val();
  var discountCode = $('#discount-code-to-apply').val();
  var numberOfSeats = $('#number_of_seats').val();
  var numberOfLiteSeats = $('#number_of_lite_seats').val();
  var numberOfFacilities = $('#number_of_facilities').val();
  var editExisting = $('#edit_existing').val();

  var params = {
    new: newSub,
    plan: plan,
    frequency: frequency,
    discount_code: discountCode,
    number_of_seats: numberOfSeats,
    number_of_lite_seats: numberOfLiteSeats,
    number_of_facilities: numberOfFacilities,
    edit_existing: editExisting
  }

  return params
}

function updateTotals(response) {
  $('.js-base-price').html(response.base_price);
  $('.js-users-subtotal').html(response.users_price);
  $('.js-lite-users-subtotal').html(response.lite_users_price);
  $('.js-facilities-subtotal').html(response.facilities_price);
  $('.js-checkout-total').html(response.total);
  $('.js-due-today-total').html(response.starter_plan_price);

  if(response.new_subscription && response.frequency == 'monthly') {
    $('#js-subscribe-to-plan .js-checkout-total').html(response.starter_plan_price);
  }

  var submitButton = $('#js-subscribe-to-plan');

  if(response.tax) {
    $('.js-tax-amount').html(response.tax);
    $('.js-tax-row').removeClass('d-none');
  } else {
    $('.js-tax-row').addClass('d-none');
  }

  if(response.discount) {
    $('.js-discount-amount').html(response.discount);
    $('.js-discount-row').removeClass('d-none');
    $('#discount-code-to-apply').hide();
  } else {
    $('.js-discount-row').addClass('d-none');
  }

  if(response.due_at_renew) {
    $('.js-renew-total').html(response.due_at_renew);
  }

  if(response.no_changes) {
    submitButton.prop('disabled', true);
  } else {
    submitButton.prop('disabled', false);
  }
}
