$(document).on("turbolinks:load", function () {
  if ($(".js-attendance-display").is("*")) {
    var booking_id = $(".js-attendance-display").data("booking-id");

    $.ajax({
      url: "/business/bookings/load_attendance.js",
      type: "GET",
      data: {
        booking_id: booking_id,
      },
      dataType: "script",
      success: () => {
        setTimeout(function () {
          if ($(".js-placeholder-day").is("*")) {
            loadDays();
          }

          if ($(".js-advance-days").is("*")) {
            $(".js-advance-days").on("click", function () {
              loadMoreDays(this);
            });
          }
        }, 500);
      },
    });
  }

  if ($(".js-report-card-select").is("*")) {
    var first_form_id = $(".js-report-card-select").val();

    $("#new-report-card-form").find(".js-report-card-form").find('input, select, textarea, button').not('.trix-input').prop('disabled', true);
    $("#report-card-" + first_form_id).find('input, select, textarea, button, .trix-input').not('.trix-input').prop('disabled', false);
    $('.selectpicker').selectpicker('refresh');  

    $(".js-report-card-select").on("change", (e) => {
      var form_id = $(e.target).val();
      var target_form = $("#report-card-" + form_id);
      var visible_form = $(e.target).parents('#new-report-card-form').find(".js-report-card-form:visible");
      
      visible_form.slideUp('fast', function() {
        $("#new-report-card-form").find(".js-report-card-form").find('input, select, textarea, button').not('.trix-input').prop('disabled', true);
        target_form.find('input, select, textarea, button').not('.trix-input').prop('disabled', false);
        target_form.slideDown('fast');
        $('.selectpicker').selectpicker('refresh');
      });
    });
  }

  if ($('.js-report-card-client-select').is('*')) {
    updateReportCardPetFields();

    $(".js-report-card-client-select").on("change", () => {
      updateReportCardPetFields();
    });
  }

  $('body').on('click', '.js-report-card-pets-edit-card .btn', function(){
    var button = $(this);
    
    setTimeout(function(){
      if (button.hasClass('active')){
        button.find('input').prop('disabled', false);

        if (button.find('.destroy-check')) {
          button.find('.destroy-check').val(false);
        }
      } else {
        if (button.find('.destroy-check')) {
          button.find('.destroy-check').val(true);
        } else {
          button.find('input').prop('disabled', true);
        }
      }
    }, 200);
  });
});

function updateReportCardPetFields() {
  var client_id = $("select.js-report-card-client-select").val();

  if (client_id) {
    var pet_fields = $('.js-report-card-pets');

    pet_fields.find('input').prop('disabled', true);
    pet_fields.find('label').hide();
    pet_fields.find("label[data-client='"+client_id+"']").show();
    pet_fields.find("label[data-client='"+client_id+"'] input").prop('disabled', false);
  }
}

function loadDays() {
  $(".js-placeholder-day").each(function (index, placeholder) {
    var booking_id = $(placeholder).data("booking-id");
    var primary_booking_id = $(placeholder).data("primary-booking-id");

    $.ajax({
      url: "/business/bookings/load_more_attendance_rows.js",
      type: "GET",
      data: {
        booking_id: booking_id,
        primary_booking_id: primary_booking_id,
      },
      dataType: "script",
      success: () => {
        setTimeout(function () {
          $(".js-register-pet").off("click"); // Unbind so we don't submit multiple times
          $(".js-register-pet").each(function (index, button) {
            $(button).on("click", function (e) {
              e.preventDefault();
              e.stopPropagation();
              submitNewRegistration(button);
            });
          });
        }, 500);
      },
    });
  });
}

function submitNewRegistration(button) {
  $(button).prop("disabled", true);
  $(button).fadeOut("fast", function () {
    $(button).siblings(".spinner").fadeIn("fast");
  });

  var url = $(button).attr("href");
  var booking_id = $(button).data("booking");
  var pet_id = $(button).data("pet");

  var data = {
    pet_id: pet_id,
    booking: {
      id: booking_id,
      booking_pets_attributes: [
        {
          state: "booked",
          pet_id: pet_id,
          booking_id: booking_id,
        },
      ],
    },
  };

  $.ajax({
    url: url,
    type: "PATCH",
    beforeSend: function (xhr) {
      xhr.setRequestHeader(
        "X-CSRF-Token",
        localStorage.getItem("bp-x-csrf-token")
      );
    },
    data: data,
    dataType: "script",
    success: () => {
      window.notyf.success("Customer registered!");
    },
    error: () => {
      window.notyf.error(
        "Sorry, something went wrong. The customer was not registered."
      );
    },
  });
}

function loadMoreDays(button) {
  var url = $(button).data("url");
  var reference_booking = $(button).data("reference-booking");
  var primary_booking = $(button).data("primary-booking");
  var chron = $(button).data("chron");

  var data = {
    reference_booking: reference_booking,
    chron: chron,
  };

  $(button).fadeOut("fast", function () {
    $.ajax({
      url: url,
      type: "GET",
      data: data,
      dataType: "script",
      success: (response) => {
        var json = JSON.parse(response);
        var html = $.parseHTML(
          '<div class="attendance-column d-none d-md-block js-placeholder-day" data-booking-id="' +
            json.booking_id +
            '" data-primary-booking-id="' +
            primary_booking +
            '"><div class="w-100 text-center"><div class="spinner spinner-border spinner-border-sm text-primary"></div></div></div>'
        );

        if (chron == "future") {
          $(button).before(html);
        } else {
          $(button).after(html);
        }

        if (json.more_days == true) {
          $(button).data("reference-booking", json.booking_id);
          $(button).fadeIn("fast");
        } else {
          $(button).remove();
        }

        loadDays();
      },
      error: () => {
        window.notyf.error("Sorry, something went wrong.");
      },
    });
  });
}
