// Node JS
require("trix");
require("ajax-bootstrap-select");
require("flatpickr");
require("jquery-timepicker/jquery.timepicker");
require("chart.js");

onmount = require("onmount");
window.onmount = onmount;

$(document).on(
  "turbolinks:load ready show.bs closed.bs load page:change",
  function () {
    onmount();
  }
);

$(document).on("turbolinks:before-cache", () => {
  onmount.teardown();
});

// Application-wide JS
require("packs/javascripts/addresses");
require("packs/javascripts/selectpicker");
require("packs/javascripts/add_multiples");
require("packs/javascripts/add_new_client");
require("packs/javascripts/filepond");
require("packs/javascripts/plyr");
require("packs/javascripts/charts");
require("packs/javascripts/stripe");
require("packs/javascripts/stripe_terminal");
require("packs/javascripts/show_hide_password");
require("packs/javascripts/draggable");
require("packs/javascripts/notyf");
require("packs/javascripts/stimulus");
require("packs/javascripts/form_validation");
require("packs/javascripts/remote_form");
require("packs/javascripts/sort_order");
require("packs/javascripts/tui-calendar");
require("packs/javascripts/resource-calendar");
require("packs/javascripts/messages");
require("packs/javascripts/highlight_text");
require("packs/javascripts/datepicker");
require("packs/javascripts/rails_confirm");
require("packs/javascripts/intercom");
require("packs/javascripts/icon_picker");
require("packs/javascripts/google_analytics");
require("packs/javascripts/direct_upload");
require("packs/javascripts/tags");
require("packs/javascripts/text_truncate");
require("packs/javascripts/trix");
var Honeybadger = require("packs/javascripts/honeybadger");
require("packs/javascripts/timer");
require("packs/javascripts/dirty_form");

// Page specific JS
require("packs/javascripts/pages/booking_schedules");
require("packs/javascripts/pages/client_discount_code_form");
require("packs/javascripts/pages/campaigns");
require("packs/javascripts/pages/packages");
require("packs/javascripts/pages/location_resources");
require("packs/javascripts/pages/membership_form");
require("packs/javascripts/pages/message_template_form");
require("packs/javascripts/pages/packages_form");
require("packs/javascripts/pages/toggler");
require("packs/javascripts/pages/reports");
require("packs/javascripts/pages/edit_booking");
require("packs/javascripts/pages/boarding");
require("packs/javascripts/pages/show_booking");
require("packs/javascripts/pages/subscription");
require("packs/javascripts/pages/search");
require("packs/javascripts/pages/client_show");
require("packs/javascripts/pages/segments");
require("packs/javascripts/pages/invoice_builder");
require("packs/javascripts/pages/onboarding");
require("packs/javascripts/pages/service_form");
require("packs/javascripts/pages/services_admin");
require("packs/javascripts/pages/series_admin");
require("packs/javascripts/pages/merge_client_or_pets");
require("packs/javascripts/pages/booking_recurring_partial");
require("packs/javascripts/pages/pos_purchase");
require("packs/javascripts/pages/waitlists");
require("packs/javascripts/pages/business/intake_templates/signature_pad");
require("packs/javascripts/pages/edit_user");
require("packs/javascripts/pages/business/billing");

// Component JS
require("packs/javascripts/components/quick_checkin");
require("packs/javascripts/components/addons_for_today");
require("packs/javascripts/components/notifications");
require("packs/javascripts/components/textarea_autogrow");

// App-wide JS. TODO Remove booking-specific stuff here into their own JS file

const setDisabledStateForABooking = () => {
  if ($("#js-client_select option:selected").text() == "New Customer") {
    $("#new_client input, #new_client select").prop("disabled", false);
    $("#new_client button.dropdown-toggle").removeClass("disabled");
    $("#new_client").slideDown();
    $("#new_pet input").prop("disabled", false);
    $("#new_pet").slideDown();
    if ($("#js-client-credits-and-invoice").is("*")) {
      $("#js-client-credits-and-invoice").slideUp(() => {
        $("#js-client-credits-and-invoice").empty();
      });
    }

    if($('#js-booking-location-select').is('*')) {
      var bookingAddressType = $('#js-booking-location-select option:selected').data('bookingAddressType');

      if(bookingAddressType == 'client_address') {
        $('#js-new-client-address-fields').show();
        $('#js-new-client-address-fields input[type=text]').attr('required', 'required');
      } else {
        $('#js-new-client-address-fields').hide();
        $('#js-new-client-address-fields input[type=text]').removeAttr('required');
      }
    }

    $("#pets_display").slideUp(() => {
      $("#pets_check_boxes").empty();
    });
  } else if ($("#new_client").is("*")) {
    $("#new_client").slideUp("fast", () => {
      $("#new_client input, #new_client select").prop("disabled", true);
      $("#new_client button.dropdown-toggle").addClass("disabled");
    });
    $("#new_pet").slideUp("fast", () => {
      $("#new_pet input").prop("disabled", true);
    });

    $("#pets_display").slideDown("fast");
    $(".new_pet_btn").fadeIn();
  }
};

const clientSelectChange = (element) => {
  setDisabledStateForABooking();

  data = $(".js-new-and-edit-booking-form").serializeArray();

  if (element.value == "new_client" || element.value == "") {
    return;
  }

  $.ajax({
    url: "/business/bookings/refresh_pets",
    type: "GET",
    data: data,
    dataType: "script",
  });

  $.ajax({
    url: "/business/bookings/refresh_details",
    type: "GET",
    data: data,
    dataType: "script",
  });
};

onmount("#js-client_select", function () {
  setDisabledStateForABooking();

  $(this).on("change", function () {
    clientSelectChange(this);
  });

  if ($(this).data("new") === "false" || $(this).data("new") === false) {
    var new_object_option = false;
  } else {
    var new_object_option = true;
  }

  $(this)
    .selectpicker()
    .ajaxSelectPicker({
      ajax: {
        url: "/business/search/client?new_object=" + new_object_option,
        beforeSend: function (xhr) {
          xhr.setRequestHeader(
            "X-CSRF-Token",
            localStorage.getItem("bp-x-csrf-token")
          );
        },
        data: function () {
          var params = {
            q: "{{{q}}}",
          };
          return params;
        },
      },
      clearOnEmpty: false,
      emptyRequest: true,
      cache: false,
      preserveSelected: false,
    });
});

onmount(".js-selectpicker-remote-search", function () {
  $(this)
    .selectpicker()
    .ajaxSelectPicker({
      ajax: {
        url: $(this).data('remote-url'),
        beforeSend: function (xhr) {
          xhr.setRequestHeader(
            "X-CSRF-Token",
            localStorage.getItem("bp-x-csrf-token")
          );
        },
        data: function () {
          var params = {
            q: "{{{q}}}",
          };
          return params;
        },
      },
      clearOnEmpty: false,
      emptyRequest: true,
      cache: false,
      preserveSelected: false,
    });
});

(function loop() {
  setTimeout(function () {
    var table = document.querySelector(".js-table-for-form-input-preview");
    if (!table) {
      setTimeout(
        function () {
          loop();
        }.bind(this),
        2000
      );
      return;
    }

    var tbody = "";

    for (const input of document.querySelectorAll(
      ".js-new-and-edit-booking-form input"
    )) {
      var css_class_for_disabled_cell = input.disabled
        ? "bg-warning"
        : "bg-success";

      var name = "<td>" + input.name + "</td>";
      var value = "<td>" + input.value.toString() + "</td>";
      var multi_client = "<td>" + input.dataset.multiClient + "</td>";
      var new_record_field =
        "<td>" + (input.dataset.newRecordField || "") + "</td>";
      var disabled =
        '<td class="' +
        css_class_for_disabled_cell +
        '">' +
        input.disabled.toString() +
        "</td>";
      tbody +=
        "<tr>" +
        name +
        value +
        multi_client +
        new_record_field +
        disabled +
        "</tr>";
    }
    $(".js-table-for-form-input-preview tbody")[0].innerHTML = tbody;

    loop();
  }, 2000);
})();

const checkinClick = (element) => {
  var $this = (check_ui = $(element));
  var existing_status = check_ui.data("checked-in");
  var new_status = !!!existing_status;
  check_ui.data("checked-in", new_status);
  check_ui.toggleClass(["btn-danger", "btn-success"]);
  var new_status = new_status ? 1 : 0;
  var input = $this
    .closest(".js-checkin-cell")
    .find("input.js-checked-in-checkbox");
  input.prop("checked", new_status);

  if (check_ui[0].dataset.submitOnToggle == "true") {
    var form = check_ui.closest("form");
    var url = form.attr("action");

    $.ajax({
      type: "POST",
      url: url,
      data: form.serialize(),
      dataType: "script",
      success: () => {
        onmount();
      },
    });
  }
};

onmount(".js-checkin-toggle", function () {
  $(this).on("click", function () {
    checkinClick(this);
  });
});

// Change bootstrap select input value when file selected
onmount(".custom-file-input", function () {
  document.querySelectorAll(".custom-file-input").forEach(bindChange);
  function bindChange(element) {
    element.addEventListener("change", function (e) {
      var fileName = e.target.files[0].name;
      var nextSibling = e.target.nextElementSibling;
      nextSibling.innerText = fileName;
    });
  }
});

$(document).on("turbolinks:load", function () {
  localStorage.setItem(
    "bp-x-csrf-token",
    document.querySelector('meta[name="csrf-token"]').content
  );

  // maintain tabs on page refresh
  $('a[data-toggle="tab"]').on("shown.bs.tab", function (e) {
    localStorage.setItem("activeTab", $(e.target).attr("href"));
  });

  var activeTab = localStorage.getItem("activeTab");
  if (activeTab) {
    setTimeout( () => {
      $('.nav-tabs a[href="' + activeTab + '"]').tab("show")
      },
      50
    );
  }

  // make a table row or other element that links to another page
  $("body").on(
    "click",
    ".clickable-row[data-link], .clickable[data-link]",
    function () {
      window.location = $(this).data("link");
    }
  );

  // Add a sticky footer

  if ($(".sticky-footer").is("*")) {
    const resizeObserver = new ResizeObserver((entries) => {
      // Document resizes before the JS can recalc
      setTimeout(function () {
        $(window).trigger("resize");
      }, 500);
    });

    resizeObserver.observe(document.body);

    $(window).on("load resize scroll", function () {
      if ($(".sticky-anchor").is("*")) {
        var offsetHeight = $(".sticky-anchor").offset().top;
        var screenHeight = $(window).scrollTop() + $(window).height();

        if (offsetHeight > screenHeight) {
          $(".sticky-footer").css("position", "fixed");
          $(".sticky-footer").css("bottom", "0");
          $(".sticky-footer").css("left", "0");
          $(".sticky-footer").css("z-index", "999");
          $(".sticky-footer").addClass("card");
          $(".sticky-footer").addClass("w-100");
          $(".sticky-footer").addClass("mb-0");
        } else {
          $(".sticky-footer").css("position", "relative");
          $(".sticky-footer").css("bottom", "");
          $(".sticky-footer").css("left", "");
          $(".sticky-footer").css("z-index", "");
          $(".sticky-footer").removeClass("card");
          $(".sticky-footer").removeClass("w-100");
          $(".sticky-footer").removeClass("mb-0");
        }
      }
    });
  }
});

$(document).on("turbolinks:load ready", function () {
  $(".selectpicker").attr("data-display", "static");
  $(window).trigger("load.bs.select.data-api");
});

$(document).on("turbolinks:before-cache", function () {
  for (item of document.querySelectorAll(".selectpicker")) {
    const $picker = $(item);
    const picker = $picker.data("selectpicker");
    if (picker) {
      picker.destroy();
      $picker.attr("data-display", "static");
      $picker.addClass("selectpicker");
    }
  }
});

onmount();

window.enforceMinMax = function(el) {
  if (el.value != "") {
    if (parseInt(el.value) < parseInt(el.min)) {
      el.value = el.min;
    }
    if (parseInt(el.value) > parseInt(el.max)) {
      el.value = el.max;
    }
  }
} 

// window.onerror = function(event: string | Event, source?: string, lineno?: number, colno?: number, error?: Error) {
window.onerror = function (event, source, lineno, colno, error) {
  // TODO: report to honeybadger
  console.log("error happening - event", event);
  console.log("error happening - source", source);
  console.log("error happening - lineno", lineno);
  console.log("error happening - colno", colno);
  console.log("error happening - error", error);

  // Handle direct-uploads failing
  const hasErrorText = /Uncaught Error: Direct upload failed: Error/;
  const res = hasErrorText.exec(event);
  // if error is actually a activestorage error, then we want to do something special like undo the editor
  if (res) {
    const re = /".*"/g;
    var text = re.exec(event)[0].replaceAll('"', "");
    var target = $(`span.attachment__name:contains('${text}')`);

    // there can be more than one trix-editor on the page, so we find the
    // element with the text and then remove it with a 'undo'.
    var trix_editor =
      target[0].parentElement.parentElement.parentElement.parentElement.editor;

    console.log("Undoing last trix change with trix_editor.undo()");
    trix_editor.undo();
    window.notyf.error(
      `Sorry, your attachment failed to upload - please try again. If this error persists, please reach out to BusyPaws staff.`
    );
  }
};

// Add handler for unhandledrejection
window.addEventListener('unhandledrejection', function(event) {
  // TODO: report to honeybadger
  // the event object has two special properties:
  // alert(event.promise); // [object Promise] - the promise that generated the error
  // alert(event.reason); // Error: Whoops! - the unhandled error object
  console.log(event.promise); // [object Promise] - the promise that generated the error
  console.log(event.reason); // Error: Whoops! - the unhandled error object
});

// new Promise(function() {
//   throw new Error("Whoops!");
// }); // no catch to handle the error

